import type { Seo } from '~/types/api'

function replaceAll(str: string, replace: Record<string, string>): string {
  return Object.keys(replace).reduce(
    (acc, key) => acc.replace(new RegExp(`{${key}}`, 'g'), replace[key]),
    str,
  )
}

export default async function useSeo(
  slug: string,
  replace: Record<string, string> = {},
): Promise<Ref<Seo>> {
  const { data } = await useAsyncData(
    `seo:${slug}`,
    () => useNuxtApp().$api.content.getSeo(slug),
    {
      default: () => ({}) as Seo,
      getCachedData: (key, nuxt): Seo =>
        nuxt.payload.data[key] || nuxt.static.data[key],
    },
  )

  return ref({
    title: replaceAll(data.value.title, replace),
    description: replaceAll(data.value.description, replace),
  })
}
